import { createSlice } from '@reduxjs/toolkit';
import { PRODUCT_CATEGORY_INTEGRATION } from '../reduxConstant';
import { changeApiLoader } from './apiLoaderSlice';
import { getAPI } from '../../utils/apiRequest';
import {
  INTGERATION_CATEGORY_DROPDOWN_LIST,
  PRODUCT_CATEGORY_DEFAULT_ITEMS,
  PRODUCT_CATEGORY_SYNC_CONNECTORS_LIST,
} from '../../constants/api';
import { interpolate } from '../../utils/common';

export const productCatSyncConnMapSlice = createSlice({
  name: PRODUCT_CATEGORY_INTEGRATION,
  initialState: {
    data: [],
    quickbooksdata: {},
    productCatDefaultItemData: [],
  },
  reducers: {
    setProductCatSyncConnMapData: (state, action) => {
      state.data = action.payload;
    },
    setProductCatSyncConnQuickbooksData: (state, action) => {
      state.quickbooksdata = {
        ...state.quickbooksdata,
        [action.payload.sync_connector_id]: action.payload.list,
      };
    },
    setProductCatDefaultItemData: (state, action) => {
      state.productCatDefaultItemData = action.payload;
    },
  },
});

export const getProductCatSyncConnMapData = (id) => (dispatch) => {
  dispatch(changeApiLoader(true));

  let successFn = (results) => {
    dispatch(setProductCatSyncConnMapData(results.data));
    results.data.forEach((item, index) => {
      dispatch(
        getProductCatSyncConnQuickbooksData(item.id, index, results.data)
      );
    });
  };

  let errorFn = (error) => {
    dispatch(changeApiLoader(false));
  };

  getAPI(
    interpolate(PRODUCT_CATEGORY_SYNC_CONNECTORS_LIST, [id]),
    successFn,
    errorFn
  );
};

export const getProductCatSyncConnQuickbooksData =
  (sync_connector_id, index, arr) => (dispatch) => {
    dispatch(changeApiLoader(true));
    let successFn = (results) => {
      if (index === arr.length - 1) {
        dispatch(changeApiLoader(false));
      }
      dispatch(
        setProductCatSyncConnQuickbooksData({
          sync_connector_id: sync_connector_id,
          list: results.data,
        })
      );
    };

    let errorFn = (error) => {
      dispatch(changeApiLoader(false));
    };

    getAPI(
      interpolate(INTGERATION_CATEGORY_DROPDOWN_LIST, [sync_connector_id]),
      successFn,
      errorFn
    );
  };

export const getProductCatDefaultItemData = (loader) => (dispatch) => {
  dispatch(changeApiLoader(true));
  let successFn = (results) => {
    if (!loader) {
      dispatch(changeApiLoader(false));
    }
    dispatch(setProductCatDefaultItemData(results.data));
  };

  let errorFn = (error) => {
    dispatch(changeApiLoader(false));
  };

  getAPI(PRODUCT_CATEGORY_DEFAULT_ITEMS, successFn, errorFn);
};

export const {
  setProductCatSyncConnMapData,
  setProductCatSyncConnQuickbooksData,
  setProductCatDefaultItemData,
} = productCatSyncConnMapSlice.actions;

export default productCatSyncConnMapSlice.reducer;
