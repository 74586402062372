import {
  CloseOutlined,
  FormOutlined,
  PlusOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  Table,
  Tabs,
  Tooltip,
  TreeSelect,
  Typography,
} from 'antd';
import { isEmpty, lowerCase } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  CATEGORY,
  DISTRIBUTOR,
  LABOR_CATEGORY,
  MANUFACTURER,
  GENERAL,
  SYNC_CONNECTOR_GENERAL_TAB_FIELDS,
  SYSTEM_OPTIONS,
  ITEM_TYPE,
  INTEGRATION_SYSTEM_OPTIONS_INACTIVE_ITEMS,
  LOGS,
  MANUFACTURER_MAPPED_STATE,
  MANUFACTURER_IN_USE_STATE,
} from '../../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getManufacturerExpandedData,
  getSyncConnectorIntegrationsData,
  getSyncConnectorIntegrationsDropdownData,
  getSystemOptionsData,
  getTargetInfoData,
  switchListSetFunction,
} from '../../../redux/Slices/systemSyncConnectorIntegrationSlice';
import { changeApiLoader } from '../../../redux/Slices/apiLoaderSlice';
import { postAPI, putAPI } from '../../../utils/apiRequest';
import {
  checkGotoInput,
  formatDate,
  formatDateAndTime,
  interpolate,
} from '../../../utils/common';
import {
  INTEGRATION_DISTRIBUTOR_CREATE,
  INTEGRATION_DISTRIBUTOR_MAPPING,
  INTEGRATION_GENERAL_UPDATE_TARGET_FLAG,
  INTEGRATION_MANUFACTURER_CREATE,
  INTEGRATION_MANUFACTURER_MAPPING,
  INTEGRATION_SYSTEM_OPTIONS_UPDATE,
  INTGERATION_CATEGORY_CREATE,
  INTGERATION_CATEGORY_MAPPING,
  INTGERATION_ITEM_TYPE_MAPPING,
  INTGERATION_LABOR_CATEGORY_CREATE,
  INTGERATION_LABOR_CATEGORY_MAPPING,
} from '../../../constants/api';
import { SyncConnectorGeneralTabEditModal } from './SyncConnectorGeneralTabEditModal';
import { SyncConnectorIntegrationQuickbooksAddModal } from './SyncConnectorIntegrationQuickbooksAddModal';
import {
  REQUIRED_FIELD_MESSAGE,
  TYPE_ERROR,
  TYPE_SUCCESS,
} from '../../../constants/message';
import { getProductCatDefaultItemData } from '../../../redux/Slices/productCatSyncConnMapSlice';
import { setMessage } from '../../../redux/Slices/messageSlice';
import { IntegrationFilters } from '../../common/IntegrationFilters';
import { _get } from '../../../utils/lodashUtils';
import {
  getColumnWidth,
  integrationType,
  checkForNoAvailableMsg,
  applyAutoWidthTable,
  getQueryParamsObj,
  getAllCategoryIds,
} from './commonKeyIntegrationMethods';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const SyncConnectorTabIntgeration = (props) => {
  const { id, typeid, tab } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [systemOptionsForm] = Form.useForm();

  const [addNewModal, setAddNewModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [activeTabKey, setActiveTabKey] = useState(tab);
  const [modalTab, setModalTab] = useState(tab);
  const [activeRecord, setActiveRecord] = useState(null);
  const [generalTabEditModal, setGeneralTabEditModal] = useState(false);
  const [generalTabEditRecord, setGeneralTabEditRecord] = useState(null);
  const [updateMappingIndex, setUpdateMappingIndex] = useState(-1);
  const [gotoPage, setGotoPage] = useState('');
  const [expandedRows, setExpandedRows] = useState([]);

  const integrationFiltersFormRef = useRef(null);

  const targetInfo = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.targetInfo
  );
  const systemOptionsData = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.systemOptionsData
  );
  const generalData = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.generalData
  );
  const distributorData = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.distributorData
  );
  const distributorDropdownData = useSelector(
    (state) =>
      state.systemSyncConnectorIntegrationReducer.distributorDropdownData
  );
  const manufacturerData = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.manufacturerData
  );
  const manufacturerExpandedData = useSelector(
    (state) =>
      state.systemSyncConnectorIntegrationReducer.manufacturerExpandedData
  );
  const manufacturerDropdownData = useSelector(
    (state) =>
      state.systemSyncConnectorIntegrationReducer.manufacturerDropdownData
  );
  const categoryData = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.categoryData
  );
  const categoryDropdownData = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.categoryDropdownData
  );
  const laborCategoryData = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.laborCategoryData
  );
  const laborCategoryDropdownData = useSelector(
    (state) =>
      state.systemSyncConnectorIntegrationReducer.laborCategoryDropdownData
  );
  const itemTypeData = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.itemTypeData
  );
  const itemTypeDropdownData = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.itemTypeDropdownData
  );
  const logsData = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.logsData
  );
  const defaultItems = useSelector(
    (state) => state.productCatSyncConnMapReducer.productCatDefaultItemData
  );
  const apiLoader = useSelector((state) => state.apiLoader.bool);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(systemOptionsData)) {
      systemOptionsForm.setFieldsValue({
        default_item_type_id: systemOptionsData.default_item_type_id,
        missing_category: systemOptionsData.missing_category
          ? JSON.parse(systemOptionsData.missing_category).action
          : undefined,
        default_value:
          systemOptionsData.missing_category &&
          JSON.parse(systemOptionsData.missing_category).action ===
            'USE_DEFAULT_VALUE'
            ? JSON.parse(systemOptionsData.missing_category).default_value
            : '',
        inactive_item_action: systemOptionsData.inactive_item_action,
        inactive_connector_action: systemOptionsData.inactive_connector_action,
      });
      if (systemOptionsData.default_target_category_id) {
        systemOptionsForm.setFieldsValue({
          default_category: String(
            systemOptionsData.default_target_category_id
          ),
        });
      }
    }
  }, [systemOptionsData, systemOptionsForm]);

  const getTableDataSource = (key) => {
    switch (key) {
      case lowerCase(GENERAL):
        return generalData;
      case lowerCase(DISTRIBUTOR):
        return distributorData.records;
      case lowerCase(MANUFACTURER):
        return manufacturerData.data;
      case lowerCase(CATEGORY):
        return categoryData;
      case lowerCase(LABOR_CATEGORY).split(' ').join('-'):
        return laborCategoryData;
      case lowerCase(ITEM_TYPE).split(' ').join('-'):
        return itemTypeData;
      case lowerCase(LOGS):
        return logsData.data;
      default:
        return;
    }
  };

  const getSelectDataSource = (key) => {
    switch (key) {
      case lowerCase(DISTRIBUTOR):
        return distributorDropdownData;
      case lowerCase(MANUFACTURER):
        return manufacturerDropdownData;
      case lowerCase(CATEGORY):
        return categoryDropdownData;
      case lowerCase(LABOR_CATEGORY).split(' ').join('-'):
        return laborCategoryDropdownData;
      case lowerCase(ITEM_TYPE).split(' ').join('-'):
        return itemTypeDropdownData;
      default:
        return;
    }
  };

  const getFields = (key) => {
    switch (key) {
      case lowerCase(DISTRIBUTOR):
        return {
          firstCol: 'name',
          secondCol: 'TargetDistributor',
          code: 'distributor_code',
          name: 'distributor_name',
          id: 'id',
          tbsId: 'distributor_id',
        };
      case lowerCase(MANUFACTURER):
        return {
          firstCol: 'description',
          secondCol: 'TargetManufacturer',
          code: 'target_manufacturer_code',
          name: 'target_manufacturer_name',
          id: 'code',
          tbsId: 'manufacturer_code',
        };
      case lowerCase(CATEGORY):
        return {
          firstCol: 'name',
          secondCol: 'TargetCategory',
          code: 'category_code',
          name: 'category_name',
          id: 'id',
          tbsId: 'category_id',
        };
      case lowerCase(LABOR_CATEGORY).split(' ').join('-'):
        return {
          firstCol: 'name',
          secondCol: 'QuickBooksLaborCategory',
          code: 'labor_category_code',
          name: 'labor_category_name',
          id: 'id',
          tbsId: 'labor_category_id',
        };
      case lowerCase(ITEM_TYPE).split(' ').join('-'):
        return {
          firstCol: 'name',
          secondCol: 'QuickBooksItemType',
          code: 'item_type_code',
          name: 'item_type_name',
          id: 'id',
          tbsId: 'item_type_id',
        };
      default:
        return;
    }
  };

  const openAddModal = (key, title, record, index) => {
    setModalTitle(`Add ${title}`);
    // setActiveTabKey(key);
    setModalTab(key);
    setActiveRecord(record);
    setAddNewModal(true);
    setUpdateMappingIndex(index);
  };

  const getUpdateMappingApi = (key) => {
    switch (key) {
      case lowerCase(DISTRIBUTOR):
        return INTEGRATION_DISTRIBUTOR_MAPPING;
      case lowerCase(MANUFACTURER):
        return INTEGRATION_MANUFACTURER_MAPPING;
      case lowerCase(CATEGORY):
        return INTGERATION_CATEGORY_MAPPING;
      case lowerCase(LABOR_CATEGORY).split(' ').join('-'):
        return INTGERATION_LABOR_CATEGORY_MAPPING;
      case lowerCase(ITEM_TYPE).split(' ').join('-'):
        return INTGERATION_ITEM_TYPE_MAPPING;
      default:
        return;
    }
  };

  const updateTableSourceAfterMapping = (key, arr) => {
    switch (key) {
      case lowerCase(DISTRIBUTOR):
        switchListSetFunction(key, dispatch, {
          data: { ...distributorData, records: arr },
        });
        break;
      case lowerCase(MANUFACTURER):
        switchListSetFunction(key, dispatch, {
          ...manufacturerData,
          data: arr,
        });
        break;
      default:
        switchListSetFunction(key, dispatch, { data: arr });
        break;
    }
  };

  const updateMapping = (key, value, option, index, record, title, fromRef) => {
    dispatch(changeApiLoader(true));
    const fields = getFields(key);
    let successFn = (result) => {
      if (key === lowerCase(CATEGORY)) {
        dispatch(
          getSyncConnectorIntegrationsData(key, id, fromRef, {
            filters: {
              name: integrationFiltersFormRef.current.getSearchField(),
            },
          })
        );
      } else {
        let arr = [...getTableDataSource(key)];
        arr[index] = {
          ...arr[index],
          [fields.secondCol]: {
            [fields.name]: option.label,
            [fields.code]: option.value,
          },
        };
        updateTableSourceAfterMapping(key, arr);
        if (key !== lowerCase(MANUFACTURER)) {
          dispatch(changeApiLoader(false));
        } else if (fromRef === 'fromMap') {
          dispatch(changeApiLoader(false));
        }
      }
      dispatch(
        setMessage({
          type: TYPE_SUCCESS,
          msg: `${title} updated successfully!`,
        })
      );
    };
    let errorFn = (error) => {
      console.log('updateMapping error', error);
      dispatch(changeApiLoader(false));
    };
    const payload = {
      [fields.tbsId]:
        key === lowerCase(MANUFACTURER)
          ? record[fields.id]
            ? record[fields.id]
            : String(record['id'])
          : record[fields.id],
      [fields.code]: typeof value === 'string' ? value : value.toString(),
    };
    postAPI(
      interpolate(getUpdateMappingApi(key), [id]),
      payload,
      successFn,
      errorFn
    );
  };

  const generalUpdateTargetFlag = (e, record) => {
    dispatch(changeApiLoader(true));
    let successFn = (results) => {
      dispatch(changeApiLoader(false));
      dispatch(getSyncConnectorIntegrationsData(activeTabKey, id));
    };
    let errorFn = (error) => {
      dispatch(changeApiLoader(false));
      console.log('errorFn in generalUpdateTargetFlag', error);
    };
    putAPI(
      interpolate(INTEGRATION_GENERAL_UPDATE_TARGET_FLAG, [id]),
      { field_name: record.field_name, is_active: e.target.checked },
      successFn,
      errorFn
    );
  };

  const renderGeneralTabColumns = (key, title) => {
    const col = [
      {
        title: 'Edit',
        dataIndex: 'editable',
        render: (value, record) =>
          value ? (
            <Tooltip title={'Edit'} overlayClassName='tooltip'>
              <FormOutlined
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setGeneralTabEditModal(true);
                  setGeneralTabEditRecord(record);
                }}
              />
            </Tooltip>
          ) : (
            ''
          ),
      },
      {
        title: 'Target Update?',
        dataIndex: 'target_update_flag',
        render: (value, record) => (
          <Checkbox
            checked={value}
            value={value}
            onChange={(e) => generalUpdateTargetFlag(e, record)}
            disabled={!record.target_update_flag_editable}
          />
        ),
      },
      {
        title: 'Field Name',
        dataIndex: 'display_name',
        render: (value) => (value ? value : '--'),
      },
      {
        title: 'Default Value',
        dataIndex: 'default_display_value',
        render: (val, record) =>
          record.target_db_type !== SYNC_CONNECTOR_GENERAL_TAB_FIELDS[5].value
            ? val
              ? val
              : '--'
            : record.name
            ? record.name
            : '--',
      },
    ];
    return col;
  };

  const dropDownRender = (menu, key, title, record, index) => {
    return (
      <>
        {menu}{' '}
        {key !== lowerCase(ITEM_TYPE).split(' ').join('-') &&
          key !== lowerCase(DISTRIBUTOR) && (
            <>
              <Divider style={{ margin: '8px 0' }} key={'add_btn'} />
              <Button
                type='text'
                style={{ width: '100%' }}
                icon={<PlusOutlined />}
                onClick={() => openAddModal(key, title, record, index)}
              >
                Add item
              </Button>
            </>
          )}
      </>
    );
  };

  const renderQuickbooksSelect = (key, title, fields, value, record, index) => {
    return (
      <Select
        value={
          value
            ? value['is_active'] === false
              ? value[fields.name]
              : value[fields.code]
            : null
        }
        style={{
          width: 220,
          height: '28px',
        }}
        onChange={(val, opt) =>
          updateMapping(key, val, opt, index, record, title, 'fromMap')
        }
        dropdownRender={(menu) =>
          dropDownRender(menu, key, title, record, index)
        }
        disabled={
          key === lowerCase(DISTRIBUTOR) && record['is_active'] === false
        }
        showSearch={true}
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={getSelectDataSource(key).map((item) => ({
          value: item[fields.code],
          key: item[fields.code],
          label: item[fields.name],
        }))}
      />
    );
  };

  const renderTreeNodes = (nodes, fields) => {
    return nodes.map((item) => {
      if (item.items) {
        return (
          <TreeSelect.TreeNode
            title={item[fields.name]}
            key={item[fields.code]}
            value={item[fields.code]}
            dataRef={item}
          >
            {renderTreeNodes(item.items, fields)}
          </TreeSelect.TreeNode>
        );
      }
      return (
        <TreeSelect.TreeNode
          value={item[fields.code]}
          title={item[fields.name]}
          {...item}
        />
      );
    });
  };

  const renderCategoryQuickbooksTree = (
    key,
    title,
    fields,
    value,
    record,
    index
  ) => {
    return (
      <TreeSelect
        value={
          value
            ? value['is_active'] === false
              ? value[fields.name]
              : value[fields.code]
            : null
        }
        style={{
          width: 220,
          height: '28px',
        }}
        onChange={(val, opt) =>
          updateMapping(key, val, opt, index, record, title, 'fromMap')
        }
        dropdownRender={(menu) => dropDownRender(menu, key, title, record)}
        showSearch={true}
        filterTreeNode={(search, node) =>
          node.title.toLowerCase().includes(search.toLowerCase())
        }
      >
        {renderTreeNodes(getSelectDataSource(key), fields)}
      </TreeSelect>
    );
  };

  const renderLogsTableColumns = (key, title) => {
    return [
      {
        title: 'Status',
        dataIndex: 'status',
        className: 'log-status-column',
        render: (value) =>
          value !== null && value !== undefined ? (
            value === 1 ? (
              <CheckCircleFilled style={{ color: 'green' }} />
            ) : (
              <ExclamationCircleFilled style={{ color: 'red' }} />
            )
          ) : (
            '--'
          ),
        onCell: () => ({
          style: {
            minWidth: 70,
          },
        }),
      },
      {
        title: 'Item Id',
        dataIndex: 'item_id',
        className: 'log-itemid-column',
        render: (value) => (value ? value : '--'),
        onCell: () => ({
          style: {
            minWidth: 100,
          },
        }),
      },
      {
        title: 'CPN',
        dataIndex: 'connector_part_number',
        className: 'log-cpn-column',
        render: (value) => (value ? value : '--'),
        ellipsis: true,
        onCell: () => ({
          style: {
            minWidth: 200,
            maxWidth: 250,
          },
        }),
      },
      {
        title: 'Date and Time',
        dataIndex: 'create_date',
        className: 'log-date-column',
        render: (value) => (value ? formatDateAndTime(value) : '--'),
        onCell: () => ({
          style: {
            minWidth: 170,
          },
        }),
      },
      {
        title: 'Message',
        dataIndex: 'log',
        className: 'log-message-column',
        render: (value) => (value ? value : '--'),
        onCell: () => ({
          style: {
            minWidth: 300,
            maxWidth: 400,
          },
        }),
      },
    ];
  };

  const renderColumns = (key, title) => {
    if (key === lowerCase(GENERAL)) {
      return renderGeneralTabColumns(key, title);
    }
    if (key === lowerCase(LOGS)) {
      return renderLogsTableColumns(key, title);
    }
    const fields = getFields(key);
    const columns = [
      {
        title: key === lowerCase(MANUFACTURER) ? 'ID' : `Blackleaf ${title}`,
        dataIndex: fields.firstCol,
        className: 'integration-table-first-col',
        render: (value, record, index) => {
          if (key === lowerCase(MANUFACTURER)) {
            return record['code']
              ? record['code']
              : record['id']
              ? record['id']
              : '--';
          }
          return value ? value : '--';
        },
        ellipsis: true,
        width: getColumnWidth(1, key),
        onCell: () => ({
          style: {
            minWidth: key === lowerCase(MANUFACTURER) ? 100 : 250,
          },
        }),
      },
      {
        title: `${integrationType(parseInt(typeid))} ${
          key === lowerCase(DISTRIBUTOR) ? 'Vendor' : title
        }`,
        dataIndex: fields.secondCol,
        render: (value, record, index) => {
          return key === lowerCase(CATEGORY)
            ? renderCategoryQuickbooksTree(
                key,
                title,
                fields,
                value,
                record,
                index
              )
            : renderQuickbooksSelect(key, title, fields, value, record, index);
        },
        ellipsis: true,
        width: getColumnWidth(3, key),
        onCell: () => ({
          style: {
            minWidth: 270,
          },
        }),
      },
    ];
    if (key === lowerCase(MANUFACTURER)) {
      columns.splice(1, 0, {
        title: 'Name',
        dataIndex: 'description',
        ellipsis: true,
        width: getColumnWidth(2, key),
        render: (value, record) =>
          value ? value : record['name'] ? record['name'] : '--',
        onCell: () => ({
          style: {
            minWidth: 280,
          },
        }),
      });
    }
    if (
      key === lowerCase(DISTRIBUTOR) ||
      key === lowerCase(MANUFACTURER) ||
      key === lowerCase(CATEGORY)
    ) {
      columns.push({
        title: '',
        dataIndex: fields.secondCol,
        className: 'distributor-quickbooks-status',
        render: (value) =>
          value && value['is_active'] === false
            ? `${integrationType(parseInt(typeid))} ${
                key === lowerCase(DISTRIBUTOR) ? 'Vendor' : title
              } is no longer active`
            : '',
        ellipsis: true,
        width: getColumnWidth(4, key),
        onCell: (record) => {
          return {
            style: {
              minWidth:
                record[fields.secondCol] &&
                record[fields.secondCol]['is_active'] === false
                  ? 300
                  : 10,
            },
          };
        },
      });
    }
    return columns;
  };

  const renderTable = (key, title) => {
    return (
      <>
        <Table
          dataSource={getTableDataSource(key)}
          columns={renderColumns(key, title)}
          rowKey={key !== GENERAL ? 'id' : 'display_name'}
          pagination={false}
          className={applyAutoWidthTable(key) ? 'integrations-table' : ''}
          expandable={{
            expandedRowKeys: expandedRows,
            onExpand: (expanded, record) => {
              if (expanded) {
                setExpandedRows((prev) => [...prev, record.id]);
              } else {
                setExpandedRows((prev) =>
                  prev.filter((key) => key !== record.id)
                );
              }
            },
          }}
          rowClassName={(record, index) => {
            if (key === lowerCase(CATEGORY)) {
              return 'table-child-row';
            }
          }}
        />
      </>
    );
  };

  const renderManufacturerTable = (key, title) => {
    return (
      <Table
        dataSource={getTableDataSource(key)}
        columns={renderColumns(key, title)}
        className={'data-table integrations-table'}
        rowKey={(record) => (record.code ? record.code : record.id)}
        rowClassName={(record) => record.id && 'individual-manufaturer-row'}
        expandable={{
          rowExpandable: (record) =>
            record.code !== undefined && record.code !== null,
          expandedRowRender: (record) => (
            <Table
              dataSource={
                manufacturerExpandedData[record.code]
                  ? manufacturerExpandedData[record.code]
                  : []
              }
              showHeader={false}
              columns={[
                {
                  dataIndex: '',
                  render: () => '',
                  className: 'child-manufacturer-column',
                  width: 108,
                },
                {
                  dataIndex: 'id',
                  render: (value) => (value ? value : '--'),
                  className: 'child-manufacturer-column',
                  width: 65,
                },
                {
                  dataIndex: 'name',
                  render: (value) => (value ? value : '--'),
                  className: 'child-manufacturer-column',
                },
                {
                  dataIndex: '',
                  render: () => '',
                  className: 'child-manufacturer-column',
                },
              ]}
              pagination={false}
              rowKey={'id'}
              style={{ margin: '12px auto' }}
            />
          ),
          onExpand: (expanded, record) => {
            if (expanded) {
              dispatch(getManufacturerExpandedData(record.code));
            }
          },
        }}
        pagination={{
          position: ['bottomRight'],
          hideOnSinglePage: false,
          showSizeChanger: true,
          pageSize: _get(manufacturerData, 'limit', 0),
          total: _get(manufacturerData, 'total', 0),
          current:
            _get(manufacturerData, 'offset', 0) /
              _get(manufacturerData, 'limit', 100) +
            1,
          showQuickJumper: {
            goButton: (
              <input
                type='number'
                aria-label='Page'
                value={gotoPage}
                onChange={(e) => {
                  setGotoPage(e.target.value);
                }}
                style={{ width: '90px' }}
                onKeyDown={(e) => {
                  if (e.key === '.' || e.key === 'e') {
                    e.preventDefault();
                  }
                  if (e.key === 'Enter') {
                    if (
                      checkGotoInput(
                        gotoPage,
                        _get(manufacturerData, 'total'),
                        _get(manufacturerData, 'limit', 0)
                      )
                    ) {
                      let offsetVal =
                        (gotoPage - 1) * _get(manufacturerData, 'limit');
                      let filtersObj = _get(manufacturerData, 'filters');
                      dispatch(
                        getSyncConnectorIntegrationsData(key, id, undefined, {
                          filters: filtersObj,
                          offset: offsetVal,
                          limit: _get(manufacturerData, 'limit'),
                        })
                      );
                    } else {
                      dispatch(
                        setMessage({
                          type: TYPE_ERROR,
                          msg: 'Please enter the correct value',
                        })
                      );
                    }
                  }
                }}
              />
            ),
          },
          locale: {
            jump_to: 'Go to ',
            jump_to_confirm: '',
          },
          pageSizeOptions: [25, 50, 100, 500],
        }}
        onChange={(paginationObj) => {
          let offsetVal =
            (_get(paginationObj, 'current') - 1) *
            _get(paginationObj, 'pageSize');
          let filtersObj = _get(manufacturerData, 'filters');
          dispatch(
            getSyncConnectorIntegrationsData(key, id, undefined, {
              filters: filtersObj,
              offset: offsetVal,
              limit: _get(paginationObj, 'pageSize'),
            })
          );
        }}
      />
    );
  };

  const renderQuickbooksMessage = (tab) => {
    return (
      <Typography.Title
        level={3}
        style={{
          marginTop: '64px',
          textAlign: 'center',
        }}
      >{`${tab} not available in ${integrationType(
        parseInt(typeid)
      )}`}</Typography.Title>
    );
  };

  const submitSystemOptionsData = (values) => {
    let data = {
      default_item_type_id: values.default_item_type_id,
      missing_category: {
        action: values.missing_category,
      },
      inactive_item_action: values.inactive_item_action,
      inactive_connector_action: values.inactive_connector_action,
    };
    data = {
      ...data,
      default_target_category_id: parseInt(values.default_category),
    };
    if (values.missing_category === 'USE_DEFAULT_VALUE') {
      data = {
        ...data,
        missing_category: {
          ...data.missing_category,
          default_value: values.default_value,
        },
      };
    }
    dispatch(changeApiLoader(true));
    const successFn = (results) => {
      dispatch(changeApiLoader(false));
      dispatch(
        setMessage({
          type: TYPE_SUCCESS,
          msg: `System options updated successfully!`,
        })
      );
    };
    const errorFn = (error) => {
      dispatch(changeApiLoader(false));
    };
    putAPI(
      interpolate(INTEGRATION_SYSTEM_OPTIONS_UPDATE, [id]),
      data,
      successFn,
      errorFn
    );
  };

  const renderSystemOptions = () => {
    return (
      <>
        <br />
        <br />
        <Form
          layout='vertical'
          form={systemOptionsForm}
          onFinish={submitSystemOptionsData}
        >
          <Form.Item
            label='For Missing Categories'
            name={'missing_category'}
            rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
          >
            <Radio.Group>
              <Radio value={'ADD_CATEGORY'}>Add Category</Radio>
              <Radio value={'USE_DEFAULT_VALUE'}>Use Default</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={`Default ${integrationType(parseInt(typeid))} Category`}
            name='default_category'
            rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
          >
            <Select
              style={{ width: '240px' }}
              options={categoryDropdownData}
              fieldNames={{ label: 'category_name', value: 'category_code' }}
              dropdownRender={(menu) =>
                dropDownRender(menu, lowerCase(CATEGORY), CATEGORY, null, -1)
              }
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.missing_category !== currentValues.missing_category
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('missing_category') === 'USE_DEFAULT_VALUE' && (
                <Form.Item
                  label='Default Value'
                  name='default_value'
                  rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
                  preserve={true}
                >
                  <Input style={{ width: '240px' }} />
                </Form.Item>
              )
            }
          </Form.Item>
          <br />
          {parseInt(typeid) !== 9 ? (
            <Form.Item
              label='Default Item Type'
              name={'default_item_type_id'}
              rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
            >
              <Select style={{ width: '240px' }}>
                {defaultItems.map((item) => {
                  return (
                    <Select.Option value={item['id']} key={item['id']}>
                      {item['name']}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          ) : (
            ''
          )}
          <Form.Item
            label='Inactive Items'
            name={'inactive_item_action'}
            rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
          >
            <Select
              style={{ width: '240px' }}
              options={INTEGRATION_SYSTEM_OPTIONS_INACTIVE_ITEMS(
                integrationType(parseInt(typeid)),
                parseInt(typeid)
              )}
            />
          </Form.Item>
          <Form.Item
            label='Inactive Connectors'
            name={'inactive_connector_action'}
            rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
          >
            <Select
              style={{ width: '240px' }}
              options={INTEGRATION_SYSTEM_OPTIONS_INACTIVE_ITEMS(
                integrationType(parseInt(typeid)),
                parseInt(typeid)
              )}
            />
          </Form.Item>
          <br />
          <br />
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              loading={apiLoader}
              className='btn-green'
            >
              {' '}
              Submit{' '}
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  };

  const renderTabItems = () => {
    const items = [
      {
        key: lowerCase(SYSTEM_OPTIONS).split(' ').join('-'),
        label: SYSTEM_OPTIONS,
        children: renderSystemOptions(),
      },
      {
        key: lowerCase(GENERAL),
        label: GENERAL,
        children: renderTable(lowerCase(GENERAL), GENERAL),
      },
      {
        key: lowerCase(DISTRIBUTOR),
        label: DISTRIBUTOR,
        children: (
          <>
            <IntegrationFilters
              tableSource={DISTRIBUTOR}
              id={id}
              ref={integrationFiltersFormRef}
              expandedRows={expandedRows}
              setExpandedRows={setExpandedRows}
            />
            {renderTable(lowerCase(DISTRIBUTOR), DISTRIBUTOR)}
          </>
        ),
      },
      {
        key: lowerCase(MANUFACTURER),
        label: MANUFACTURER,
        children: checkForNoAvailableMsg(
          lowerCase(MANUFACTURER),
          parseInt(typeid)
        ) ? (
          renderQuickbooksMessage('Manufacturer')
        ) : (
          <>
            <IntegrationFilters
              tableSource={MANUFACTURER}
              id={id}
              ref={integrationFiltersFormRef}
              expandedRows={expandedRows}
              setExpandedRows={setExpandedRows}
            />
            {renderManufacturerTable(lowerCase(MANUFACTURER), MANUFACTURER)}
          </>
        ),
      },
      {
        key: lowerCase(CATEGORY),
        label: CATEGORY,
        children: (
          <>
            <IntegrationFilters
              tableSource={CATEGORY}
              id={id}
              ref={integrationFiltersFormRef}
              expandedRows={expandedRows}
              setExpandedRows={setExpandedRows}
            />
            {renderTable(lowerCase(CATEGORY), CATEGORY)}
          </>
        ),
      },
      {
        key: lowerCase(LABOR_CATEGORY).split(' ').join('-'),
        label: LABOR_CATEGORY,
        children: checkForNoAvailableMsg(
          lowerCase(LABOR_CATEGORY).split(' ').join('-'),
          parseInt(typeid)
        )
          ? renderQuickbooksMessage('Labor Category')
          : renderTable(
              lowerCase(LABOR_CATEGORY).split(' ').join('-'),
              LABOR_CATEGORY
            ),
      },
    ];
    if (parseInt(typeid) !== 9) {
      items.push({
        key: lowerCase(ITEM_TYPE).split(' ').join('-'),
        label: ITEM_TYPE,
        children: renderTable(
          lowerCase(ITEM_TYPE).split(' ').join('-'),
          ITEM_TYPE
        ),
      });
    }

    items.push({
      key: lowerCase(LOGS),
      label: LOGS,
      children: (
        <>
          <IntegrationFilters tableSource={LOGS} id={id} />
          {renderTable(lowerCase(LOGS), LOGS)}
        </>
      ),
    });

    return items;
  };

  const onTabChange = (activeKey) => {
    navigate(
      `/system/sync-connectors/${typeid}/${id}/integrations/${activeKey}`
    );
  };

  const getQueryParams = (tab) => {
    const qp = Object.fromEntries(searchParams.entries());
    let intFilters = getQueryParamsObj(tab, qp, {});
    return intFilters;
  };

  const updateTabData = (tab) => {
    if (tab !== lowerCase(SYSTEM_OPTIONS).split(' ').join('-')) {
      let intDataFliters = getQueryParams(tab);
      if (
        tab !== lowerCase(DISTRIBUTOR) &&
        tab !== lowerCase(MANUFACTURER) &&
        tab !== lowerCase(CATEGORY)
      ) {
        dispatch(
          getSyncConnectorIntegrationsData(tab, id, undefined, {
            filters: intDataFliters,
          })
        );
      }
      if (tab !== lowerCase(GENERAL) && tab !== lowerCase(LOGS)) {
        if (integrationFiltersFormRef && integrationFiltersFormRef.current) {
          integrationFiltersFormRef.current.resetForm();
          integrationFiltersFormRef.current.resetFields();
        }
        if (tab === lowerCase(MANUFACTURER) && isEmpty(intDataFliters)) {
          intDataFliters = { ...intDataFliters, in_use: true };
        }
        dispatch(
          getSyncConnectorIntegrationsDropdownData(
            tab,
            id,
            'onTabChange',
            intDataFliters
          )
        );
      }
    } else {
      dispatch(getProductCatDefaultItemData(true));
      dispatch(getSystemOptionsData(id, true));
      dispatch(
        getSyncConnectorIntegrationsDropdownData(
          lowerCase(CATEGORY),
          id,
          'systemOptions',
          undefined
        )
      );
    }
  };

  useEffect(() => {
    dispatch(getTargetInfoData(id));
  }, []);

  useEffect(() => {
    setActiveTabKey(tab);
    updateTabData(tab);
  }, [tab]);

  useEffect(() => {
    let qp = getQueryParams(tab);
    if (expandedRows.length === 0 && qp.name !== undefined) {
      const ids = getAllCategoryIds(categoryData, []);
      setExpandedRows(ids);
    }
  }, [categoryData]);

  const getAddUrl = (key) => {
    switch (key) {
      case lowerCase(DISTRIBUTOR):
        return INTEGRATION_DISTRIBUTOR_CREATE;
      case lowerCase(MANUFACTURER):
        return INTEGRATION_MANUFACTURER_CREATE;
      case lowerCase(CATEGORY):
        return INTGERATION_CATEGORY_CREATE;
      case lowerCase(LABOR_CATEGORY).split(' ').join('-'):
        return INTGERATION_LABOR_CATEGORY_CREATE;
      default:
        return;
    }
  };

  const addNew = (values) => {
    dispatch(changeApiLoader(true));
    let successFn = (result) => {
      const { data } = result;
      if (activeTabKey !== lowerCase(SYSTEM_OPTIONS).split(' ').join('-')) {
        dispatch(changeApiLoader(false));
        dispatch(getSyncConnectorIntegrationsDropdownData(activeTabKey, id));
        if (
          activeTabKey === lowerCase(CATEGORY) ||
          activeTabKey === lowerCase(MANUFACTURER)
        ) {
          const fields = getFields(activeTabKey);
          let title =
            activeTabKey === lowerCase(CATEGORY) ? CATEGORY : MANUFACTURER;
          updateMapping(
            activeTabKey,
            data[fields.code],
            { children: values.name, value: data[fields.code].toString() },
            updateMappingIndex,
            activeRecord,
            title,
            'fromAdd'
          );
        }
      } else {
        dispatch(
          getSyncConnectorIntegrationsDropdownData(
            lowerCase(CATEGORY),
            id,
            'systemOptions',
            undefined
          )
        );
        systemOptionsForm.setFieldsValue({
          default_category: data.category_code,
        });
      }
      setAddNewModal(false);
    };
    let errorFn = (error) => {
      dispatch(changeApiLoader(false));
    };

    let payload = {
      [getFields(modalTab).name]: values.name,
    };
    if (modalTab === lowerCase(CATEGORY)) {
      payload = {
        ...payload,
        parent_category_code: values.makeParent
          ? null
          : values.parent_category_code,
      };
    } else if (
      modalTab !== lowerCase(DISTRIBUTOR) &&
      modalTab !== lowerCase(MANUFACTURER)
    ) {
      payload = {
        ...payload,
        [getFields(modalTab).code]: values.code,
      };
    }

    postAPI(
      interpolate(getAddUrl(modalTab), [id]),
      payload,
      successFn,
      errorFn
    );
  };

  const generalTabRecordUpdate = () => {
    setGeneralTabEditModal(false);
  };

  return (
    <Drawer
      placement='left'
      open={true}
      closable={false}
      size='large'
      style={{ width: '80vw' }}
    >
      <div
        className='custom-spin'
        style={{ display: apiLoader ? 'flex' : 'none' }}
      >
        <Spin spinning={apiLoader} />
      </div>
      <Typography.Title level={2}>
        Integration
        <Button
          className='pull-right no-border'
          icon={<CloseOutlined />}
          type={'ghost'}
          onClick={() => {
            navigate('/system/sync-connectors');
          }}
        />
      </Typography.Title>
      {Object.keys(targetInfo).length > 0 && (
        <Typography.Title level={4}>
          {targetInfo.target_name}&nbsp;
          <Typography.Text>
            (Setup Date:{' '}
            {targetInfo.setup_date ? formatDate(targetInfo.setup_date) : '--'})
          </Typography.Text>
        </Typography.Title>
      )}
      <br />
      <Tabs
        type='card'
        className='system-config-tabs'
        activeKey={activeTabKey}
        items={renderTabItems()}
        onChange={(activeKey) => onTabChange(activeKey)}
      />
      <SyncConnectorIntegrationQuickbooksAddModal
        title={modalTitle}
        open={addNewModal}
        onCancel={() => setAddNewModal(false)}
        onSubmit={addNew}
        activeTabKey={modalTab}
        categoryDropdownData={categoryDropdownData}
      />
      <SyncConnectorGeneralTabEditModal
        open={generalTabEditModal}
        id={id}
        record={generalTabEditRecord}
        onOk={generalTabRecordUpdate}
        onCancel={() => setGeneralTabEditModal(false)}
      />
    </Drawer>
  );
};

export default SyncConnectorTabIntgeration;
