import React, { useEffect, useState } from 'react';
import ModalCom from '../../common/Modal';
import { Button, Form, Input, Radio, Select, Spin, TreeSelect } from 'antd';
import {
  GENERAL,
  SYNC_CONNECTOR_GENERAL_TAB_FIELDS,
} from '../../../constants/constants';
import {
  REQUIRED_FIELD_MESSAGE,
  TYPE_SUCCESS,
} from '../../../constants/message';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGeneralEditFieldTableColumnData,
  getGeneralEditSalesTaxClassificationData,
  getGeneralEditTargetData,
  getSyncConnectorIntegrationsData,
} from '../../../redux/Slices/systemSyncConnectorIntegrationSlice';
import { putAPI } from '../../../utils/apiRequest';
import { interpolate } from '../../../utils/common';
import { INTEGRATION_GENERAL_EDIT_MAP_DEFAULT } from '../../../constants/api';
import { changeApiLoader } from '../../../redux/Slices/apiLoaderSlice';
import { lowerCase } from 'lodash';
import { setMessage } from '../../../redux/Slices/messageSlice';

export const SyncConnectorGeneralTabEditModal = (props) => {
  const { id, open, onOk, onCancel, record, ...restProps } = props;
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [field, setField] = useState('');
  const [selectedFieldTableIndex, setSelectedFieldTableIndex] = useState(-1);

  const fieldTableColumnData = useSelector(
    (state) =>
      state.systemSyncConnectorIntegrationReducer
        .generalEditFieldTableColumnData
  );
  const targetData = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.generalEditTargetData
  );
  const salesTaxClassificationData = useSelector(
    (state) =>
      state.systemSyncConnectorIntegrationReducer
        .generalEditSalesTaxClassificationData
  );
  const apiLoader = useSelector((state) => state.apiLoader.bool);

  useEffect(() => {
    if (open && record && record.target_db_type) {
      setField(record.target_db_type);
      form.setFieldsValue({
        field: record.target_db_type,
      });
      switch (record.target_db_type) {
        case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[5].value:
          if (record.field_name === 'sales_tax') {
            const defaultValue = JSON.parse(record.default_value);
            if (
              defaultValue.taxable &&
              defaultValue.tax_type &&
              defaultValue.tax_type === 'classification'
            )
              dispatch(getGeneralEditSalesTaxClassificationData(id));
          } else {
            dispatch(getGeneralEditTargetData(id, record.field_name));
          }
          break;
        case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[3].value:
          dispatch(getGeneralEditFieldTableColumnData(id));
          break;
        default:
          break;
      }
    }
  }, [open]);

  useEffect(() => {
    if (record && record.default_value && fieldTableColumnData.length > 0) {
      if (
        record.target_db_type === SYNC_CONNECTOR_GENERAL_TAB_FIELDS[3].value
      ) {
        let tableVal = record.default_value
          .split('.')[0]
          .split(']')[0]
          .split('[')[1];
        fieldTableColumnData.forEach((item, index) => {
          if (item.name === tableVal) {
            setSelectedFieldTableIndex(index);
          }
        });
      }
    }
  }, [fieldTableColumnData]);

  const onFormChange = (formData) => {
    const { field, tableVal, salesTaxType } = formData;
    if (!!field) {
      setField(field);
      if (field === SYNC_CONNECTOR_GENERAL_TAB_FIELDS[3].value) {
        dispatch(getGeneralEditFieldTableColumnData(id));
      }
    }
    if (!!tableVal) {
      form.setFieldsValue({ columnVal: undefined });
    }
    if (!!salesTaxType && salesTaxType === 'classification') {
      dispatch(getGeneralEditSalesTaxClassificationData(id));
    }
  };

  const handleFieldTableChange = (value, option) => {
    setSelectedFieldTableIndex(option.index);
  };

  const renderTreeNodes = (nodes) =>
    nodes.map((item) => {
      if (item.items && item.items.length > 0) {
        return (
          <TreeSelect.TreeNode
            title={item.name}
            key={item.id}
            value={item.id}
            dataRef={item}
            selectable={false}
            className={'classification-parent-tree-node'}
          >
            {renderTreeNodes(item.items)}
          </TreeSelect.TreeNode>
        );
      }
      return (
        <TreeSelect.TreeNode
          value={item.id}
          key={item.id}
          title={item.name}
          {...item}
          selectable={true}
        />
      );
    });

  const salesTaxUI = () => {
    if (record) {
      const defaultValue = JSON.parse(record.default_value);
      return (
        <>
          <Form.Item
            label='Sales Tax'
            name='salesTaxVal'
            preserve={false}
            initialValue={defaultValue.taxable ? 'taxable' : 'non_taxable'}
          >
            <Radio.Group>
              <Radio value={'taxable'}>Taxable</Radio>
              <Radio value={'non_taxable'}>Non-Taxable</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.salesTaxVal !== currentValues.salesTaxVal
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('salesTaxVal') === 'taxable' && (
                <Form.Item
                  label='Tax Type'
                  name='salesTaxType'
                  rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
                  initialValue={
                    defaultValue.tax_type ? defaultValue.tax_type : undefined
                  }
                  preserve={false}
                >
                  <Radio.Group>
                    <Radio value='location'>Location</Radio>
                    <Radio value='classification'>Classification</Radio>
                  </Radio.Group>
                </Form.Item>
              )
            }
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.salesTaxType !== currentValues.salesTaxType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('salesTaxType') === 'classification' && (
                <Form.Item
                  label='Classification Type'
                  name='classificationId'
                  rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
                  initialValue={
                    defaultValue.tax_classification_id
                      ? defaultValue.tax_classification_id
                      : undefined
                  }
                  preserve={false}
                >
                  <TreeSelect>
                    {renderTreeNodes(salesTaxClassificationData)}
                  </TreeSelect>
                </Form.Item>
              )
            }
          </Form.Item>
        </>
      );
    } else {
      return '';
    }
  };

  const formUiOnFieldChange = () => {
    switch (field) {
      case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[0].value:
        return (
          <Form.Item
            label='String Value'
            name={'stringValue'}
            initialValue={
              record.target_db_type ===
              SYNC_CONNECTOR_GENERAL_TAB_FIELDS[0].value
                ? record.default_value
                : undefined
            }
            preserve={false}
            rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
          >
            <Input type='text' />
          </Form.Item>
        );
      case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[1].value:
        return '';
      case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[2].value:
        return '';
      case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[3].value:
        return (
          <div>
            <Form.Item
              label='Table'
              name={'tableVal'}
              rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
              preserve={false}
              initialValue={
                record.target_db_type ===
                  SYNC_CONNECTOR_GENERAL_TAB_FIELDS[3].value &&
                record.default_value.split('.')[0].split(']')[0].split('[')[1]
              }
            >
              <Select
                options={fieldTableColumnData.map((item, index) => ({
                  value: item.name,
                  label: item.display_name,
                  index: index,
                }))}
                onChange={handleFieldTableChange}
              />
            </Form.Item>
            <Form.Item
              label='Column'
              name={'columnVal'}
              rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
              dependencies={['tableVal']}
              preserve={false}
              initialValue={
                record.target_db_type ===
                  SYNC_CONNECTOR_GENERAL_TAB_FIELDS[3].value &&
                record.default_value.split('.')[1].split(']')[0].split('[')[1]
              }
            >
              <Select
                disabled={selectedFieldTableIndex === -1}
                options={
                  fieldTableColumnData.length > 0 &&
                  selectedFieldTableIndex !== -1
                    ? fieldTableColumnData[selectedFieldTableIndex].columns.map(
                        (item) => ({
                          value: item.name,
                          label: item.display_name,
                        })
                      )
                    : []
                }
              />
            </Form.Item>
          </div>
        );
      case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[4].value:
        return (
          <Form.Item
            label='Decimal Value'
            name={'decimalValue'}
            initialValue={
              record.target_db_type ===
              SYNC_CONNECTOR_GENERAL_TAB_FIELDS[4].value
                ? record.default_value
                : undefined
            }
            preserve={false}
            rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
          >
            <Input type='number' step='any' />
          </Form.Item>
        );
      case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[5].value:
        if (record.field_name === 'sales_tax') {
          return salesTaxUI();
        } else {
          return (
            <div>
              <Form.Item
                label={`${record && record.display_name} value`}
                name={'targetValue'}
                initialValue={record.default_value}
                rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
                preserve={false}
              >
                <Select
                  options={targetData.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
              </Form.Item>
            </div>
          );
        }
      case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[6].value:
        if (record.field_name === 'ns_tax_schedule') {
          return (
            <Form.Item
              name='booleanValue'
              preserve={false}
              initialValue={record.default_value}
              rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
            >
              <Radio.Group>
                <Radio value={true} key={'1'}>
                  Taxable
                </Radio>
                <Radio value={false} key={'0'}>
                  Non-taxable
                </Radio>
              </Radio.Group>
            </Form.Item>
          );
        }
        return '';
      default:
        return '';
    }
  };

  const getDefaultValue = (values) => {
    switch (values.field) {
      case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[0].value:
        return values.stringValue;
      case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[1].value:
        return 'NULL';
      case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[2].value:
        return '';
      case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[3].value:
        return `[${values.tableVal}].[${values.columnVal}]`;
      case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[4].value:
        return values.decimalValue;
      case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[5].value:
        return values.targetValue;
      case SYNC_CONNECTOR_GENERAL_TAB_FIELDS[6].value:
        return values.booleanValue;
      default:
        return;
    }
  };

  const onUpdate = (values) => {
    dispatch(changeApiLoader(true));
    let data = {
      field_name: record.field_name,
      target_db_type: values.field,
    };
    if (record.field_name === 'sales_tax') {
      data['taxable'] = values.salesTaxVal === 'taxable';
      if (values.salesTaxVal === 'taxable') {
        data['tax_type'] = values.salesTaxType;
      }
      if (values.salesTaxType && values.salesTaxType === 'classification') {
        data['tax_classification_id'] = values.classificationId;
      }
    } else {
      data['default_value'] = getDefaultValue(values);
    }
    const successFn = (results) => {
      dispatch(changeApiLoader(false));
      dispatch(getSyncConnectorIntegrationsData(lowerCase(GENERAL), id));
      setSelectedFieldTableIndex(-1);
      dispatch(
        setMessage({
          type: TYPE_SUCCESS,
          msg: `Field updated successfully!`,
        })
      );
      onCancel();
    };
    const errorFn = (error) => {
      dispatch(changeApiLoader(false));
      console.log('errorFn', error);
    };
    putAPI(
      interpolate(INTEGRATION_GENERAL_EDIT_MAP_DEFAULT, [id]),
      data,
      successFn,
      errorFn
    );
  };

  const disableRadioButton = (value, record) => {
    if (
      value === 'Boolean' &&
      record &&
      record.target_db_type !== SYNC_CONNECTOR_GENERAL_TAB_FIELDS[6].value
    ) {
      return true;
    }
    if (
      value !== 'Boolean' &&
      record &&
      record.target_db_type === SYNC_CONNECTOR_GENERAL_TAB_FIELDS[6].value
    ) {
      return true;
    }
    if (
      value === 'Target' &&
      record &&
      record.target_db_type !== SYNC_CONNECTOR_GENERAL_TAB_FIELDS[5].value
    ) {
      return true;
    }
    if (
      value !== 'Target' &&
      record &&
      record.target_db_type === SYNC_CONNECTOR_GENERAL_TAB_FIELDS[5].value
    ) {
      return true;
    }
    return false;
  };

  return (
    <ModalCom
      title={'Update Field'}
      open={open}
      onCancel={() => {
        setSelectedFieldTableIndex(-1);
        onCancel();
      }}
      centered={true}
      footer={null}
      width={640}
      {...restProps}
    >
      <Spin spinning={apiLoader}>
        <Form
          layout='vertical'
          form={form}
          initialValues={{
            field: record ? record.target_db_type : '',
          }}
          onFinish={onUpdate}
          onValuesChange={onFormChange}
        >
          <Form.Item label='Field' name={'field'} preserve={false}>
            <Radio.Group>
              {SYNC_CONNECTOR_GENERAL_TAB_FIELDS.map(({ display, value }) => (
                <Radio
                  disabled={disableRadioButton(value, record)}
                  value={value}
                  key={value}
                >
                  {display}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          {formUiOnFieldChange()}
          <br />
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Update
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </ModalCom>
  );
};
