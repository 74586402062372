import React, { useState } from 'react';
import ModalCom from '../../common/Modal';
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { REQUIRED_FIELD_MESSAGE } from '../../../constants/message';
import { useSelector } from 'react-redux';
import { lowerCase } from 'lodash';
import {
  CATEGORY,
  DISTRIBUTOR,
  MANUFACTURER,
  SYSTEM_OPTIONS,
} from '../../../constants/constants';
import { useParams } from 'react-router-dom';

export const SyncConnectorIntegrationQuickbooksAddModal = (props) => {
  const {
    title,
    open,
    activeTabKey,
    categoryDropdownData,
    onCancel,
    onSubmit,
  } = props;

  const { tab } = useParams();

  const [form] = Form.useForm();

  const apiLoader = useSelector((state) => state.apiLoader.bool);

  const renderSecondField = () => {
    if (activeTabKey === lowerCase(CATEGORY)) {
      return (
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.makeParent !== currentValues.makeParent
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('makeParent') === false && (
              <Form.Item
                name={'parent_category_code'}
                label={'Parent Category'}
                rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
                preserve={false}
              >
                <Select
                  options={categoryDropdownData}
                  fieldNames={{
                    label: 'category_name',
                    value: 'category_code',
                  }}
                />
              </Form.Item>
            )
          }
        </Form.Item>
      );
    } else {
      return (
        <Form.Item
          name={'code'}
          label={'Code'}
          rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
          preserve={false}
        >
          <Input />
        </Form.Item>
      );
    }
  };

  return (
    <ModalCom title={title} open={open} onCancel={onCancel} footer={null}>
      <Form layout='vertical' form={form} key={title} onFinish={onSubmit}>
        <Row align={'middle'} gutter={16}>
          <Col span={14}>
            <Form.Item
              name={'name'}
              label={'Name'}
              rules={[{ required: true, message: REQUIRED_FIELD_MESSAGE }]}
              preserve={false}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            {activeTabKey === lowerCase(CATEGORY) && (
              <Form.Item
                name={'makeParent'}
                label={'Make Parent Category'}
                valuePropName='checked'
                initialValue={
                  tab === lowerCase(SYSTEM_OPTIONS).split(' ').join('-')
                    ? true
                    : false
                }
                preserve={false}
              >
                <Checkbox />
              </Form.Item>
            )}
          </Col>
          <Col span={14}>
            {activeTabKey !== lowerCase(DISTRIBUTOR) &&
              activeTabKey !== lowerCase(MANUFACTURER) &&
              renderSecondField()}
          </Col>
          <Col span={18}>
            <Form.Item>
              <Button
                style={{ width: '50%', margin: '12px auto' }}
                type='primary'
                loading={apiLoader}
                htmlType='submit'
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalCom>
  );
};
