import { lowerCase } from 'lodash';
import {
  CATEGORY,
  DISTRIBUTOR,
  GENERAL,
  LABOR_CATEGORY,
  LOGS,
  MANUFACTURER,
  MANUFACTURER_IN_USE_STATE,
  MANUFACTURER_MAPPED_STATE,
} from '../../../constants/constants';
import moment from 'moment';

export const getColumnWidth = (columnNum, key) => {
  switch (columnNum) {
    case 1:
      switch (key) {
        case lowerCase(MANUFACTURER):
          return 50;
        case lowerCase(DISTRIBUTOR):
          return 70;
        case lowerCase(CATEGORY):
          return 300;
        default:
          return 100;
      }
    case 2:
      switch (key) {
        case lowerCase(MANUFACTURER):
          return 100;
        default:
          return 120;
      }
    case 3:
      switch (key) {
        case lowerCase(DISTRIBUTOR):
          return 90;
        default:
          return 120;
      }
    case 4:
      switch (key) {
        case lowerCase(MANUFACTURER):
          return 100;
        default:
          return 120;
      }
    default:
      return 100;
  }
};

export const checkIntegrationButton = (syncConnectorTypeId) => {
  if (
    syncConnectorTypeId === 6 ||
    syncConnectorTypeId === 9 ||
    syncConnectorTypeId === 2 ||
    syncConnectorTypeId === 8
  ) {
    return true;
  } else {
    return false;
  }
};

export const integrationType = (syncConnectorTypeId) => {
  switch (syncConnectorTypeId) {
    case 6:
      return 'Quickbooks';
    case 9:
      return 'Fieldhub';
    case 8:
      return 'Netsuite';
    default:
      return '';
  }
};

export const checkForNoAvailableMsg = (key, syncConnectorTypeId) => {
  switch (key) {
    case lowerCase(MANUFACTURER):
      if (syncConnectorTypeId === 6 || syncConnectorTypeId === 8) {
        return true;
      }
      return false;
    case lowerCase(LABOR_CATEGORY).split(' ').join('-'):
      if (checkIntegrationButton(syncConnectorTypeId)) {
        return true;
      }
      return false;
    default:
      return false;
  }
};

export const applyAutoWidthTable = (key) => {
  switch (key) {
    case lowerCase(GENERAL):
      return false;
    default:
      return true;
  }
};

export const updateFilterFields = (field, value) => {
  switch (field) {
    case 'manufaturerMappedState':
      if (value === 'all') {
        return MANUFACTURER_MAPPED_STATE.ALL;
      } else {
        return value === 'true'
          ? MANUFACTURER_MAPPED_STATE.MAPPED
          : MANUFACTURER_MAPPED_STATE.UNMAPPED;
      }
    case 'manufacturerInUse':
      if (value === 'all') {
        return MANUFACTURER_IN_USE_STATE.ALL;
      } else {
        return value === 'true'
          ? MANUFACTURER_IN_USE_STATE.IN_USE
          : MANUFACTURER_IN_USE_STATE.NOT_IN_USE;
      }
    default:
      return value;
  }
};

const mappedAndUseStateParamValues = (value) => {
  switch (value) {
    case MANUFACTURER_MAPPED_STATE.ALL:
    case MANUFACTURER_IN_USE_STATE.ALL:
      return 'all';
    case MANUFACTURER_MAPPED_STATE.MAPPED:
    case MANUFACTURER_IN_USE_STATE.IN_USE:
      return 'true';
    case MANUFACTURER_MAPPED_STATE.UNMAPPED:
    case MANUFACTURER_IN_USE_STATE.NOT_IN_USE:
      return 'false';
    default:
      return value;
  }
};

export const updateQueryParamObj = (field, value, obj) => {
  if (!value) return obj;
  switch (field) {
    case 'searchVal':
      return { ...obj, name: value };
    case 'manufaturerMappedState':
      return { ...obj, mappingstatus: mappedAndUseStateParamValues(value) };
    case 'manufacturerInUse':
      return { ...obj, inuse: mappedAndUseStateParamValues(value) };
    case 'status':
      return { ...obj, status: value };
    case 'item_id':
      return { ...obj, itemid: value };
    case 'connector_part_number':
      return { ...obj, cpn: value };
    case 'create_date':
      return { ...obj, date: moment(value['$d']).format('YYYY-MM-DD') };
    default:
      return obj;
  }
};

export const clearFormObj = (tableSource) => {
  switch (tableSource) {
    case DISTRIBUTOR:
    case CATEGORY:
      return { searchVal: '' };
    case MANUFACTURER:
      return {
        searchVal: '',
        manufaturerMappedState: '',
        manufacturerInUse: MANUFACTURER_IN_USE_STATE.IN_USE,
      };
    case LOGS:
      return {
        status: '',
        item_id: '',
        connector_part_number: '',
        create_date: null,
      };
    default:
      return {};
  }
};

export const getQueryParamsObj = (tab, qp, intFilters) => {
  switch (tab) {
    case lowerCase(MANUFACTURER):
      if (qp.name) {
        intFilters = {
          ...intFilters,
          description: qp.name,
        };
      }
      if (qp.inuse && qp.inuse !== 'all') {
        intFilters = {
          ...intFilters,
          in_use: qp.inuse === 'true' ? true : false,
        };
      }
      if (qp.mappingstatus && qp.mappingstatus !== 'all') {
        intFilters = {
          ...intFilters,
          maping_status: qp.mappingstatus === 'true' ? true : false,
        };
      }
      return intFilters;
    case lowerCase(DISTRIBUTOR):
    case lowerCase(CATEGORY):
      if (qp.name) {
        intFilters = {
          ...intFilters,
          name: qp.name,
        };
      }
      return intFilters;
    case lowerCase(LOGS):
      if (qp.status && qp.status !== 'all') {
        intFilters = {
          ...intFilters,
          status: qp.status === 'success' ? true : false,
        };
      }
      if (qp.itemid) {
        intFilters = { ...intFilters, item_id: parseInt(qp.itemid) };
      }
      if (qp.cpn) {
        intFilters = {
          ...intFilters,
          connector_part_number: qp.cpn,
        };
      }
      if (qp.date) {
        intFilters = {
          ...intFilters,
          create_date: qp.date,
        };
      }
      return intFilters;
    default:
      return {};
  }
};

export const getAllCategoryIds = (arr, ids) => {
  arr.forEach((item) => {
    ids.push(item.id);
    if (item.children && item.children.length > 0) {
      ids = getAllCategoryIds(item.children, ids);
    }
  });

  return ids;
};
